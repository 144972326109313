@charset "UTF-8";
/* Heading */
/* Lead */
/* FontFamily */
@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/MarkPro-Regular.eot?#prefix");
  src: url("fonts/MarkPro-Regular.eot?#iefix") format("eot"), url("fonts/MarkPro-Regular.otf") format("otf"), url("fonts/MarkPro-Regular.woff") format("woff"), url("fonts/MarkPro-Regular.woff2") format("woff2"), url("fonts/MarkPro-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("fonts/MarkPro-Bold.eot?#prefix");
  src: url("fonts/MarkPro-Bold.eot?#iefix") format("eot"), url("fonts/MarkPro-Bold.otf") format("otf"), url("fonts/MarkPro-Bold.woff") format("woff"), url("fonts/MarkPro-Bold.woff2") format("woff2"), url("fonts/MarkPro-Bold.ttf") format("truetype"); }

/* Text VAL */
body .hero {
  font-size: 61px;
  line-height: 1.2623; }

body h1, body .h1 {
  font-size: 59px;
  line-height: 1.18644; }

body h2, body .h2 {
  font-size: 45px;
  line-height: 1.26667; }

body h3, body .h3 {
  font-size: 40px;
  line-height: 1.225; }

body h4, body .h4 {
  font-size: 30px;
  line-height: 1.63333; }

body h5, body .h5 {
  font-size: 26px;
  line-height: 1.19231; }

.f-68 {
  font-size: 68px;
  line-height: 1.02941; }

.f-25 {
  font-size: 25px;
  line-height: 1.24; }

.f-20 {
  font-size: 20px;
  line-height: 1.25; }

.f-17 {
  font-size: 17px;
  line-height: 1.23529; }

.f-16 {
  font-size: 16px;
  line-height: 1.25; }

@media screen and (max-width: 1199px) {
  body .hero {
    font-size: 40px; }
  .f-20 {
    font-size: 17px; } }

@media screen and (max-width: 575px) {
  body .hero {
    font-size: 31px; }
  body h3, body .h3 {
    font-size: 27px;
    line-height: 1; }
  body h4, body .h4 {
    font-size: 20px;
    line-height: 1.35; }
  .f-20 {
    font-size: 15px;
    line-height: 1.2666666667; } }

/* Grid Modifiers */
/* Grid Responsive Prefix */
/* Container Responsive Width*/
/* Container Responsive Width */
.page-header-projects {
  padding-bottom: 14px;
  margin-bottom: -273px; }
  .page-header-projects.page-header {
    padding-top: 12.26vw; }
  .page-header-projects .page-header-content {
    padding-left: 35px;
    margin-left: 0; }
  .page-header-projects .page-header-img {
    margin-top: -191px; }
  .page-header-projects .page-header-picture {
    position: relative;
    z-index: 2; }

.page-projects {
  position: relative;
  background-color: #FFF;
  padding-top: 141px;
  padding-bottom: 190px; }
  .page-projects .filters {
    position: relative;
    padding-top: 50px;
    margin-bottom: 74px;
    z-index: 3; }
    .page-projects .filters .row {
      flex-wrap: unset;
      white-space: nowrap;
      overflow-y: auto; }
  .page-projects .filter-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 25px;
    font-weight: 400;
    color: #2A3546; }
    .page-projects .filter-item:not(:last-child) {
      margin-right: 4%; }
    .page-projects .filter-item .icon {
      min-width: 71px;
      max-width: 71px;
      flex-basis: 71px;
      height: 71px;
      background-image: url("../img/icons/eye-black.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: auto;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-clip: border-box;
      border: 1px solid #56C8C7;
      margin-right: 15px; }
    .page-projects .filter-item:hover, .page-projects .filter-item.active {
      font-weight: 600; }
      .page-projects .filter-item:hover .icon, .page-projects .filter-item.active .icon {
        background-color: #56C8C7;
        background-image: url("../img/icons/eye-white.svg"); }
  .page-projects .project-item {
    display: flex;
    flex-wrap: wrap;
    background-color: #FFF;
    background-image: url("../img/bg/wave-project-box-list.svg");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    background-clip: border-box;
    box-shadow: 0 0 30px rgba(70, 146, 152, 0.37);
    padding: 24px; }
    .page-projects .project-item:not(:last-child) {
      margin-bottom: 86px; }
    .page-projects .project-item .btn-blue {
      min-width: 211px; }
    .page-projects .project-item.hys {
      display: none; }
    .page-projects .project-item.sys {
      display: flex; }
  .page-projects .project-title {
    color: #2A3546;
    margin-bottom: 4px; }
  .page-projects .project-date {
    display: block;
    color: #54BEBF;
    margin-bottom: 15px; }
  .page-projects .project-desc {
    color: #2A3546;
    line-height: 1.25;
    margin-bottom: 21px; }
  .page-projects .project-category {
    border-top: 1px solid #D3D3D3;
    border-bottom: 1px solid #D3D3D3;
    padding-top: 25px;
    margin-bottom: 34px; }
    .page-projects .project-category-item {
      display: inline-flex;
      align-items: center;
      flex-direction: column;
      color: #2A3546;
      margin-bottom: 25px; }
      .page-projects .project-category-item img {
        margin-bottom: 9px; }
      .page-projects .project-category-item:not(:last-child) {
        margin-right: 4%; }
  .page-projects .project-left {
    max-width: 39.3%;
    flex-basis: 39.3%; }
    .page-projects .project-left img {
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
      background-clip: border-box; }
  .page-projects .project-right {
    max-width: 60.7%;
    flex-basis: 60.7%;
    padding-top: 13px;
    padding-right: 3.5%;
    padding-left: 3%; }

@media screen and (max-width: 1680px) {
  .page-header-projects {
    margin-bottom: 0; }
  .page-projects {
    padding-top: 0; } }

@media screen and (max-width: 1199px) {
  .page-header-projects .page-header-img {
    margin-top: -112px; }
  .page-projects .filters {
    padding-top: 30px;
    margin-bottom: 30px; }
  .page-projects .filter-item {
    font-size: 20px; }
    .page-projects .filter-item:not(:last-child) {
      margin-right: 20px; }
    .page-projects .filter-item .icon {
      min-width: 50px;
      max-width: 50px;
      flex-basis: 50px;
      height: 50px;
      background-size: 26px auto;
      margin-right: 10px; }
  .page-projects .project-title {
    font-size: 40px; }
  .page-projects .project-date {
    font-size: 30px; }
  .page-projects .project-category-item:not(:last-child) {
    margin-right: 5px; }
  .page-projects .project-category-item.f-25 {
    font-size: 20px; } }

@media screen and (max-width: 991px) {
  .page-header-projects.page-header {
    padding-top: 90px; }
  .page-header-projects .page-header-content {
    position: relative;
    z-index: 3; }
  .page-header-projects .page-header-img {
    margin-top: -70px; }
  .page-projects .filters {
    padding-top: 30px;
    margin-bottom: 30px; }
  .page-projects .filter-item {
    font-size: 20px; }
    .page-projects .filter-item:not(:last-child) {
      margin-right: 20px; }
    .page-projects .filter-item .icon {
      min-width: 50px;
      max-width: 50px;
      flex-basis: 50px;
      height: 50px;
      background-size: 26px auto;
      margin-right: 10px; }
  .page-projects .project-item {
    max-width: calc(100% - 30px);
    flex-basis: calc(100% - 30px);
    padding: 18px;
    margin-right: auto;
    margin-left: auto; }
    .page-projects .project-item:not(:last-child) {
      margin-bottom: 40px; }
  .page-projects .project-title {
    font-size: 24px;
    margin-bottom: 1px; }
  .page-projects .project-date {
    font-size: 26px;
    margin-bottom: 4px; }
  .page-projects .project-left, .page-projects .project-right {
    max-width: 100%;
    flex-basis: 100%; }
  .page-projects .project-category {
    padding-top: 15px; }
    .page-projects .project-category–item {
      margin-bottom: 15px; }
  .page-projects .project-left img {
    width: 100%; } }

@media screen and (max-width: 768px) {
  .page-header-projects.page-header {
    padding-top: 33px; }
  .page-header-projects .page-header-img {
    margin-top: 0; }
  .page-header-projects .page-header-content {
    order: 2; }
  .page-header-projects .page-header-picture {
    order: 1; }
    .page-header-projects .page-header-picture img {
      margin-left: auto;
      margin-right: -15px; } }

@media screen and (max-width: 575px) {
  .page-projects {
    padding-bottom: 60px; }
    .page-projects .filter-item {
      padding: 0 15px; }
      .page-projects .filter-item:not(:last-child) {
        margin-right: 0; }
    .page-projects .project-item .btn-blue {
      width: 100%; }
    .page-projects .project-category {
      display: flex;
      align-items: center;
      padding-bottom: 15px;
      margin-bottom: 25px; }
      .page-projects .project-category-item {
        flex-grow: 1;
        height: 100%;
        margin-bottom: 0; }
        .page-projects .project-category-item.f-25 {
          font-size: 0;
          text-indent: -999px; }
        .page-projects .project-category-item img {
          width: auto;
          height: 30px;
          margin-bottom: 0; } }
