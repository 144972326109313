/* Heading */
/* Lead */
/* FontFamily */
@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/MarkPro-Regular.eot?#prefix");
  src: url("fonts/MarkPro-Regular.eot?#iefix") format("eot"), url("fonts/MarkPro-Regular.otf") format("otf"), url("fonts/MarkPro-Regular.woff") format("woff"), url("fonts/MarkPro-Regular.woff2") format("woff2"), url("fonts/MarkPro-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("fonts/MarkPro-Bold.eot?#prefix");
  src: url("fonts/MarkPro-Bold.eot?#iefix") format("eot"), url("fonts/MarkPro-Bold.otf") format("otf"), url("fonts/MarkPro-Bold.woff") format("woff"), url("fonts/MarkPro-Bold.woff2") format("woff2"), url("fonts/MarkPro-Bold.ttf") format("truetype"); }

/* Text VAL */
body .hero {
  font-size: 61px;
  line-height: 1.2623; }

body h1, body .h1 {
  font-size: 59px;
  line-height: 1.18644; }

body h2, body .h2 {
  font-size: 45px;
  line-height: 1.26667; }

body h3, body .h3 {
  font-size: 40px;
  line-height: 1.225; }

body h4, body .h4 {
  font-size: 30px;
  line-height: 1.63333; }

body h5, body .h5 {
  font-size: 26px;
  line-height: 1.19231; }

.f-68 {
  font-size: 68px;
  line-height: 1.02941; }

.f-25 {
  font-size: 25px;
  line-height: 1.24; }

.f-20 {
  font-size: 20px;
  line-height: 1.25; }

.f-17 {
  font-size: 17px;
  line-height: 1.23529; }

.f-16 {
  font-size: 16px;
  line-height: 1.25; }

@media screen and (max-width: 1199px) {
  body .hero {
    font-size: 40px; }
  .f-20 {
    font-size: 17px; } }

@media screen and (max-width: 575px) {
  body .hero {
    font-size: 31px; }
  body h3, body .h3 {
    font-size: 27px;
    line-height: 1; }
  body h4, body .h4 {
    font-size: 20px;
    line-height: 1.35; }
  .f-20 {
    font-size: 15px;
    line-height: 1.2666666667; } }

/* Grid Modifiers */
/* Grid Responsive Prefix */
/* Container Responsive Width*/
/* Container Responsive Width */
.page-header {
  position: relative;
  background-image: url("../img/bg/dotted-repeatable.svg");
  background-position: left top;
  background-repeat: repeat;
  background-size: auto;
  margin-top: 39px; }
  .page-header-content {
    position: relative;
    margin-left: 137px;
    z-index: 1; }
  .page-header-item.sys .page-header-title span em, .page-header-item.sys .page-header-title strong em {
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  .page-header-item.sys .page-header-title:after {
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    visibility: visible; }
  .page-header-item.sys .page-header-desc {
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    visibility: visible; }
  .page-header-title {
    position: relative;
    color: #FFF;
    text-transform: uppercase; }
    .page-header-title span, .page-header-title strong {
      display: block;
      overflow: hidden;
      -moz-transition: all 400ms ease-in-out;
      -o-transition: all 400ms ease-in-out;
      -webkit-transition: all 400ms ease-in-out;
      transition: all 400ms ease-in-out; }
      .page-header-title span em, .page-header-title strong em {
        -moz-transition: all 400ms ease-in-out;
        -o-transition: all 400ms ease-in-out;
        -webkit-transition: all 400ms ease-in-out;
        transition: all 400ms ease-in-out;
        font-style: normal;
        -ms-transform: translateY(100%);
        -webkit-transform: translateY(100%);
        -moz-transform: translateY(100%);
        -o-transform: translateY(100%);
        transform: translateY(100%); }
    .page-header-title span em {
      display: inline-flex;
      font-weight: 400;
      background-color: #44C3B8;
      padding: 6px 20px;
      transition-delay: 200ms; }
    .page-header-title strong {
      margin-left: 59px;
      margin-top: -5px; }
      .page-header-title strong em {
        display: table;
        background-color: #2A3546;
        padding: 7px 21px;
        transition-delay: 400ms; }
    .page-header-title span, .page-header-title strong {
      display: block;
      overflow: hidden;
      -moz-transition: all 400ms ease-in-out;
      -o-transition: all 400ms ease-in-out;
      -webkit-transition: all 400ms ease-in-out;
      transition: all 400ms ease-in-out; }
    .page-header-title:after {
      content: '';
      display: block;
      position: absolute;
      left: -32px;
      bottom: -28px;
      width: 514px;
      height: 90px;
      background-image: url("../img/page-title-after.svg");
      background-position: left top;
      background-repeat: no-repeat;
      background-size: auto;
      -ms-transform: translateY(10px);
      -webkit-transform: translateY(10px);
      -moz-transform: translateY(10px);
      -o-transform: translateY(10px);
      transform: translateY(10px);
      opacity: 0;
      visibility: hidden;
      -moz-transition: all 400ms ease-in-out;
      -o-transition: all 400ms ease-in-out;
      -webkit-transition: all 400ms ease-in-out;
      transition: all 400ms ease-in-out;
      transition-delay: 600ms; }
  .page-header-subtitle {
    font-weight: 100;
    margin-top: 54px;
    margin-bottom: 12px; }
  .page-header-desc {
    max-width: 554px;
    -ms-transform: translateY(20px);
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
    visibility: hidden;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
    transition-delay: 800ms;
    margin-top: 53px;
    margin-left: 11px; }
    .page-header-desc p {
      color: #2A3546; }
    .page-header-desc .btn {
      margin-top: 30px; }
  .page-header-img-mobil {
    display: none; }

@media screen and (max-width: 1680px) {
  .page-header-content {
    margin-left: 0; } }

@media screen and (max-width: 1199px) {
  .page-header-title strong em {
    -ms-transform: translateY(110%);
    -webkit-transform: translateY(110%);
    -moz-transform: translateY(110%);
    -o-transform: translateY(110%);
    transform: translateY(110%); }
  .page-header-title:after {
    left: 1px;
    bottom: -15px;
    height: 60px;
    background-size: contain; }
  .page-header-desc {
    max-width: 400px;
    margin-top: 30px;
    margin-left: 0; }
    .page-header-desc .btn {
      margin-top: 30px; } }

@media screen and (max-width: 768px) {
  .page-header {
    margin-top: 0; }
    .page-header-img-mobil {
      display: block; }
    .page-header-img-desktop {
      display: none; }
    .page-header-desc {
      text-align: center;
      margin-right: auto;
      margin-left: auto; } }

@media screen and (max-width: 575px) {
  .page-header-title strong {
    padding: 6px 21px;
    margin-left: 35px; }
  .page-header-title:after {
    left: -15px;
    bottom: -19px;
    width: 298px;
    height: 50px; }
  .page-header-desc {
    margin-top: 43px; } }
