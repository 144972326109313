/* Heading */
/* Lead */
/* FontFamily */
@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/MarkPro-Regular.eot?#prefix");
  src: url("fonts/MarkPro-Regular.eot?#iefix") format("eot"), url("fonts/MarkPro-Regular.otf") format("otf"), url("fonts/MarkPro-Regular.woff") format("woff"), url("fonts/MarkPro-Regular.woff2") format("woff2"), url("fonts/MarkPro-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("fonts/MarkPro-Bold.eot?#prefix");
  src: url("fonts/MarkPro-Bold.eot?#iefix") format("eot"), url("fonts/MarkPro-Bold.otf") format("otf"), url("fonts/MarkPro-Bold.woff") format("woff"), url("fonts/MarkPro-Bold.woff2") format("woff2"), url("fonts/MarkPro-Bold.ttf") format("truetype"); }

/* Text VAL */
body .hero {
  font-size: 61px;
  line-height: 1.2623; }

body h1, body .h1 {
  font-size: 59px;
  line-height: 1.18644; }

body h2, body .h2 {
  font-size: 45px;
  line-height: 1.26667; }

body h3, body .h3 {
  font-size: 40px;
  line-height: 1.225; }

body h4, body .h4 {
  font-size: 30px;
  line-height: 1.63333; }

body h5, body .h5 {
  font-size: 26px;
  line-height: 1.19231; }

.f-68 {
  font-size: 68px;
  line-height: 1.02941; }

.f-25 {
  font-size: 25px;
  line-height: 1.24; }

.f-20 {
  font-size: 20px;
  line-height: 1.25; }

.f-17 {
  font-size: 17px;
  line-height: 1.23529; }

.f-16 {
  font-size: 16px;
  line-height: 1.25; }

@media screen and (max-width: 1199px) {
  body .hero {
    font-size: 40px; }
  .f-20 {
    font-size: 17px; } }

@media screen and (max-width: 575px) {
  body .hero {
    font-size: 31px; }
  body h3, body .h3 {
    font-size: 27px;
    line-height: 1; }
  body h4, body .h4 {
    font-size: 20px;
    line-height: 1.35; }
  .f-20 {
    font-size: 15px;
    line-height: 1.2666666667; } }

/* Grid Modifiers */
/* Grid Responsive Prefix */
/* Container Responsive Width*/
/* Container Responsive Width */
html.scrolled-down .header {
  position: fixed; }

html.scrolled-down .navbar {
  background-color: #FFF;
  margin-top: 0; }

html.menu-open .navbar-menu .bar {
  background-color: transparent;
  transition-delay: 0s; }
  html.menu-open .navbar-menu .bar:before, html.menu-open .navbar-menu .bar:after {
    top: 0;
    width: 30px;
    transition-delay: 0s, 0.3s, 0s; }
  html.menu-open .navbar-menu .bar:before {
    transform: rotate3d(0, 0, 1, 45deg); }
  html.menu-open .navbar-menu .bar:after {
    transform: rotate3d(0, 0, 1, -45deg); }

html.menu-open .navbar-right {
  -ms-transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  transition-delay: 0s;
  -webkit-transition-delay: 0s; }
