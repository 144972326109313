/* Grid Modifiers */
/* Grid Responsive Prefix */
/* Container Responsive Width*/
/* Container Responsive Width */
.container, .container-fluid, [class^="col-"] {
  padding-left: 15px;
  padding-right: 15px; }

.container {
  margin: 0 auto; }

[class^="col-"] {
  width: 100%; }

.col {
  flex-grow: 1; }
  .col-auto {
    margin-left: auto;
    margin-right: auto; }

.col-1 {
  max-width: 8.33333%;
  flex-basis: 8.33333%; }

.col-2 {
  max-width: 16.66667%;
  flex-basis: 16.66667%; }

.col-3 {
  max-width: 25%;
  flex-basis: 25%; }

.col-4 {
  max-width: 33.33333%;
  flex-basis: 33.33333%; }

.col-5 {
  max-width: 41.66667%;
  flex-basis: 41.66667%; }

.col-6 {
  max-width: 50%;
  flex-basis: 50%; }

.col-7 {
  max-width: 58.33333%;
  flex-basis: 58.33333%; }

.col-8 {
  max-width: 66.66667%;
  flex-basis: 66.66667%; }

.col-9 {
  max-width: 75%;
  flex-basis: 75%; }

.col-10 {
  max-width: 83.33333%;
  flex-basis: 83.33333%; }

.col-11 {
  max-width: 91.66667%;
  flex-basis: 91.66667%; }

.col-12 {
  max-width: 100%;
  flex-basis: 100%; }

@media screen and (max-width: 1680px) {
  .col-xxl-1 {
    max-width: 8.33333%;
    flex-basis: 8.33333%; }
  .col-xxl-2 {
    max-width: 16.66667%;
    flex-basis: 16.66667%; }
  .col-xxl-3 {
    max-width: 25%;
    flex-basis: 25%; }
  .col-xxl-4 {
    max-width: 33.33333%;
    flex-basis: 33.33333%; }
  .col-xxl-5 {
    max-width: 41.66667%;
    flex-basis: 41.66667%; }
  .col-xxl-6 {
    max-width: 50%;
    flex-basis: 50%; }
  .col-xxl-7 {
    max-width: 58.33333%;
    flex-basis: 58.33333%; }
  .col-xxl-8 {
    max-width: 66.66667%;
    flex-basis: 66.66667%; }
  .col-xxl-9 {
    max-width: 75%;
    flex-basis: 75%; }
  .col-xxl-10 {
    max-width: 83.33333%;
    flex-basis: 83.33333%; }
  .col-xxl-11 {
    max-width: 91.66667%;
    flex-basis: 91.66667%; }
  .col-xxl-12 {
    max-width: 100%;
    flex-basis: 100%; } }

@media screen and (max-width: 991px) {
  .col-md-1 {
    max-width: 8.33333%;
    flex-basis: 8.33333%; }
  .col-md-2 {
    max-width: 16.66667%;
    flex-basis: 16.66667%; }
  .col-md-3 {
    max-width: 25%;
    flex-basis: 25%; }
  .col-md-4 {
    max-width: 33.33333%;
    flex-basis: 33.33333%; }
  .col-md-5 {
    max-width: 41.66667%;
    flex-basis: 41.66667%; }
  .col-md-6 {
    max-width: 50%;
    flex-basis: 50%; }
  .col-md-7 {
    max-width: 58.33333%;
    flex-basis: 58.33333%; }
  .col-md-8 {
    max-width: 66.66667%;
    flex-basis: 66.66667%; }
  .col-md-9 {
    max-width: 75%;
    flex-basis: 75%; }
  .col-md-10 {
    max-width: 83.33333%;
    flex-basis: 83.33333%; }
  .col-md-11 {
    max-width: 91.66667%;
    flex-basis: 91.66667%; }
  .col-md-12 {
    max-width: 100%;
    flex-basis: 100%; } }

@media screen and (max-width: 768px) {
  .col-sm-1 {
    max-width: 8.33333%;
    flex-basis: 8.33333%; }
  .col-sm-2 {
    max-width: 16.66667%;
    flex-basis: 16.66667%; }
  .col-sm-3 {
    max-width: 25%;
    flex-basis: 25%; }
  .col-sm-4 {
    max-width: 33.33333%;
    flex-basis: 33.33333%; }
  .col-sm-5 {
    max-width: 41.66667%;
    flex-basis: 41.66667%; }
  .col-sm-6 {
    max-width: 50%;
    flex-basis: 50%; }
  .col-sm-7 {
    max-width: 58.33333%;
    flex-basis: 58.33333%; }
  .col-sm-8 {
    max-width: 66.66667%;
    flex-basis: 66.66667%; }
  .col-sm-9 {
    max-width: 75%;
    flex-basis: 75%; }
  .col-sm-10 {
    max-width: 83.33333%;
    flex-basis: 83.33333%; }
  .col-sm-11 {
    max-width: 91.66667%;
    flex-basis: 91.66667%; }
  .col-sm-12 {
    max-width: 100%;
    flex-basis: 100%; } }

@media screen and (max-width: 575px) {
  .col-xs-1 {
    max-width: 8.33333%;
    flex-basis: 8.33333%; }
  .col-xs-2 {
    max-width: 16.66667%;
    flex-basis: 16.66667%; }
  .col-xs-3 {
    max-width: 25%;
    flex-basis: 25%; }
  .col-xs-4 {
    max-width: 33.33333%;
    flex-basis: 33.33333%; }
  .col-xs-5 {
    max-width: 41.66667%;
    flex-basis: 41.66667%; }
  .col-xs-6 {
    max-width: 50%;
    flex-basis: 50%; }
  .col-xs-7 {
    max-width: 58.33333%;
    flex-basis: 58.33333%; }
  .col-xs-8 {
    max-width: 66.66667%;
    flex-basis: 66.66667%; }
  .col-xs-9 {
    max-width: 75%;
    flex-basis: 75%; }
  .col-xs-10 {
    max-width: 83.33333%;
    flex-basis: 83.33333%; }
  .col-xs-11 {
    max-width: 91.66667%;
    flex-basis: 91.66667%; }
  .col-xs-12 {
    max-width: 100%;
    flex-basis: 100%; } }

.row {
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
  flex-direction: row;
  margin-left: -15px;
  margin-right: -15px; }

.container {
  max-width: 1580px; }

@media screen and (max-width: 1680px) {
  .container {
    max-width: 1170px; } }

@media screen and (max-width: 1199px) {
  .container {
    max-width: 960px; } }

@media screen and (max-width: 991px) {
  .container {
    max-width: 720px; } }

@media screen and (max-width: 768px) {
  .container {
    max-width: 540px; } }
