/* Heading */
/* Lead */
/* FontFamily */
@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/MarkPro-Regular.eot?#prefix");
  src: url("fonts/MarkPro-Regular.eot?#iefix") format("eot"), url("fonts/MarkPro-Regular.otf") format("otf"), url("fonts/MarkPro-Regular.woff") format("woff"), url("fonts/MarkPro-Regular.woff2") format("woff2"), url("fonts/MarkPro-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("fonts/MarkPro-Bold.eot?#prefix");
  src: url("fonts/MarkPro-Bold.eot?#iefix") format("eot"), url("fonts/MarkPro-Bold.otf") format("otf"), url("fonts/MarkPro-Bold.woff") format("woff"), url("fonts/MarkPro-Bold.woff2") format("woff2"), url("fonts/MarkPro-Bold.ttf") format("truetype"); }

/* Text VAL */
body .hero {
  font-size: 61px;
  line-height: 1.2623; }

body h1, body .h1 {
  font-size: 59px;
  line-height: 1.18644; }

body h2, body .h2 {
  font-size: 45px;
  line-height: 1.26667; }

body h3, body .h3 {
  font-size: 40px;
  line-height: 1.225; }

body h4, body .h4 {
  font-size: 30px;
  line-height: 1.63333; }

body h5, body .h5 {
  font-size: 26px;
  line-height: 1.19231; }

.f-68 {
  font-size: 68px;
  line-height: 1.02941; }

.f-25 {
  font-size: 25px;
  line-height: 1.24; }

.f-20 {
  font-size: 20px;
  line-height: 1.25; }

.f-17 {
  font-size: 17px;
  line-height: 1.23529; }

.f-16 {
  font-size: 16px;
  line-height: 1.25; }

@media screen and (max-width: 1199px) {
  body .hero {
    font-size: 40px; }
  .f-20 {
    font-size: 17px; } }

@media screen and (max-width: 575px) {
  body .hero {
    font-size: 31px; }
  body h3, body .h3 {
    font-size: 27px;
    line-height: 1; }
  body h4, body .h4 {
    font-size: 20px;
    line-height: 1.35; }
  .f-20 {
    font-size: 15px;
    line-height: 1.2666666667; } }

/* Grid Modifiers */
/* Grid Responsive Prefix */
/* Container Responsive Width*/
/* Container Responsive Width */
.page-header-project {
  padding-bottom: 14px; }
  .page-header-project.page-header {
    padding-top: 139px; }
  .page-header-project .page-header-title.hero {
    font-size: 45px; }
  .page-header-project .page-header-title span em {
    max-width: 507px;
    padding: 17px 20px; }
  .page-header-project .page-header-title strong em {
    padding: 17px 20px; }
  .page-header-project .page-header-content {
    padding-left: 35px;
    margin-left: 0; }
  .page-header-project .page-header-img {
    position: absolute;
    top: -23px;
    right: 95px; }

.page-project {
  padding-bottom: 109px; }
  .page-project-title {
    padding-top: 203px; }
  .page-project .project-category {
    display: flex;
    flex-wrap: wrap;
    padding-top: 65px; }
    .page-project .project-category-item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 196px;
      height: 83px;
      color: #2A3546;
      box-shadow: 0 0 15px #44C3B8;
      -webkit-border-radius: 52px;
      -moz-border-radius: 52px;
      border-radius: 52px;
      background-clip: border-box;
      padding: 0 15px;
      margin: 0 13px 59px; }
      .page-project .project-category-item img {
        margin-right: 11px; }

@media screen and (max-width: 1680px) {
  .page-header-project .page-header-img {
    width: 55vw;
    height: auto; }
  .page-header-project .page-header-content {
    padding-left: 0; } }

@media screen and (max-width: 1440px) {
  .page-project .project-category-item {
    margin: 0 10px 59px; } }

@media screen and (max-width: 1199px) {
  .page-header-project .row {
    align-items: flex-start; }
  .page-header-project .page-header-title.hero {
    font-size: 30px; }
  .page-header-project .page-header-title span {
    padding: 6px 20px; }
  .page-header-project .page-header-title strong {
    padding: 6px 20px;
    margin-top: -25px; }
  .page-header-project .page-header-subtitle {
    font-size: 30px;
    line-height: 1.24; }
  .page-header-project .page-header-img {
    right: 0; }
  .page-project-title {
    padding-top: 100px; }
  .page-project-categories {
    max-width: 100%;
    flex-basis: 100%; } }

@media screen and (max-width: 991px) {
  .page-header-project.page-header {
    padding-top: 31px; }
  .page-header-project .page-header-img {
    position: static;
    width: 371px;
    margin-left: auto;
    margin-right: -15px; }
  .page-project {
    padding-bottom: 21px; }
    .page-project .project-category {
      justify-content: center;
      padding-top: 24px; }
      .page-project .project-category-item {
        margin-bottom: 48px; } }

@media screen and (max-width: 575px) {
  .page-header-project .page-header-content {
    padding-left: 15px; }
  .page-header-project .page-header-title.hero {
    font-size: 31px; }
  .page-header-project .page-header-subtitle {
    font-size: 25px;
    margin-top: 0;
    margin-bottom: 21px; }
  .page-header-project .page-header-desc .btn {
    margin-top: 13px; }
  .page-project-title {
    padding-top: 80px; }
  .page-project .project-category-item {
    font-size: 20px;
    min-width: 159px;
    height: 67px; }
    .page-project .project-category-item img {
      width: auto;
      height: 30px; } }
