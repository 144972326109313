/* Heading */
/* Lead */
/* FontFamily */
@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/MarkPro-Regular.eot?#prefix");
  src: url("fonts/MarkPro-Regular.eot?#iefix") format("eot"), url("fonts/MarkPro-Regular.otf") format("otf"), url("fonts/MarkPro-Regular.woff") format("woff"), url("fonts/MarkPro-Regular.woff2") format("woff2"), url("fonts/MarkPro-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("fonts/MarkPro-Bold.eot?#prefix");
  src: url("fonts/MarkPro-Bold.eot?#iefix") format("eot"), url("fonts/MarkPro-Bold.otf") format("otf"), url("fonts/MarkPro-Bold.woff") format("woff"), url("fonts/MarkPro-Bold.woff2") format("woff2"), url("fonts/MarkPro-Bold.ttf") format("truetype"); }

/* Text VAL */
body .hero {
  font-size: 61px;
  line-height: 1.2623; }

body h1, body .h1 {
  font-size: 59px;
  line-height: 1.18644; }

body h2, body .h2 {
  font-size: 45px;
  line-height: 1.26667; }

body h3, body .h3 {
  font-size: 40px;
  line-height: 1.225; }

body h4, body .h4 {
  font-size: 30px;
  line-height: 1.63333; }

body h5, body .h5 {
  font-size: 26px;
  line-height: 1.19231; }

.f-68 {
  font-size: 68px;
  line-height: 1.02941; }

.f-25 {
  font-size: 25px;
  line-height: 1.24; }

.f-20 {
  font-size: 20px;
  line-height: 1.25; }

.f-17 {
  font-size: 17px;
  line-height: 1.23529; }

.f-16 {
  font-size: 16px;
  line-height: 1.25; }

@media screen and (max-width: 1199px) {
  body .hero {
    font-size: 40px; }
  .f-20 {
    font-size: 17px; } }

@media screen and (max-width: 575px) {
  body .hero {
    font-size: 31px; }
  body h3, body .h3 {
    font-size: 27px;
    line-height: 1; }
  body h4, body .h4 {
    font-size: 20px;
    line-height: 1.35; }
  .f-20 {
    font-size: 15px;
    line-height: 1.2666666667; } }

/* Grid Modifiers */
/* Grid Responsive Prefix */
/* Container Responsive Width*/
/* Container Responsive Width */
.page-header-main {
  padding-bottom: 13vw; }
  .page-header-main.page-header {
    padding-top: calc(14.25vw - 35px); }
  .page-header-main .page-header-img {
    position: absolute;
    top: 31px;
    right: 66px; }

.page-main-introduction {
  padding-top: 134px; }
  .page-main-introduction-icon {
    margin-bottom: -3px; }
  .page-main-introduction-title {
    font-weight: 400;
    color: #2A3546;
    margin-bottom: -4px; }
  .page-main-introduction-subtitle {
    color: #2A3546;
    margin-bottom: 35px; }
  .page-main-introduction-content {
    max-width: 1091px;
    margin-right: auto;
    margin-left: auto; }
    .page-main-introduction-content p:not(.text-blue) {
      color: #707070;
      margin-bottom: 23px; }
    .page-main-introduction-content .btn {
      margin-top: 74px; }
  .page-main-introduction-picture {
    margin-bottom: -73px; }
    .page-main-introduction-picture-mobil {
      display: none; }
  .page-main-introduction-box {
    display: flex; }
    .page-main-introduction-box-icon {
      margin-right: 12px; }
    .page-main-introduction-box h3 {
      color: #2A3546;
      margin-bottom: 22px; }

.page-main-services {
  padding-top: 159px; }
  .page-main-services-left {
    max-width: 38%;
    flex-basis: 38%; }
    .page-main-services-left-mobil {
      display: none; }
  .page-main-services-right {
    padding-top: 81px; }
  .page-main-services-title {
    font-weight: 400;
    color: #2A3546; }
  .page-main-services-subtitle {
    color: #2A3546;
    margin-bottom: 60px; }
  .page-main-services .page-main-introduction-box-1, .page-main-services .page-main-introduction-box-2 {
    min-height: 268px;
    padding-bottom: 30px; }
  .page-main-services .page-main-introduction-box-3, .page-main-services .page-main-introduction-box-4 {
    min-height: 245px;
    padding-bottom: 30px; }

.page-main-projects {
  padding-top: 17.1vw; }
  .page-main-projects .col-12.text-uppercase {
    display: none; }
  .page-main-projects-box-inner {
    cursor: default;
    position: relative;
    background-color: #FFF;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    background-clip: border-box;
    background-image: url("../img/bg/wave-project-box.svg");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: 0 0 30px rgba(70, 146, 152, 0.37);
    -moz-transition: transform 300ms ease;
    -o-transition: transform 300ms ease;
    -webkit-transition: transform 300ms ease;
    transition: transform 300ms ease;
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    padding: 27px 27px 42px 27px; }
    .page-main-projects-box-inner:hover {
      -ms-transform: scale(1.2);
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);
      z-index: 1; }
      .page-main-projects-box-inner:hover .page-main-projects-box-picture .img-resp {
        opacity: 0.42; }
      .page-main-projects-box-inner:hover .page-main-projects-box-action {
        opacity: 1;
        visibility: visible; }
  .page-main-projects-box-picture {
    position: relative;
    margin-bottom: 30px; }
    .page-main-projects-box-picture .img-resp {
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
      background-clip: border-box;
      -moz-transition: opacity 300ms ease;
      -o-transition: opacity 300ms ease;
      -webkit-transition: opacity 300ms ease;
      transition: opacity 300ms ease; }
  .page-main-projects-box-action {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background-clip: border-box;
    opacity: 0;
    visibility: hidden;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
    z-index: 1; }
    .page-main-projects-box-action-circle {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      min-width: 69px;
      width: 69px;
      height: 69px;
      background-color: #FFF;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-clip: border-box;
      box-shadow: 0 0 10px #70d0cf;
      margin: 0 17px; }
  .page-main-projects-box-title.h4 {
    color: #2A3546;
    line-height: 1.0333333333;
    margin-bottom: 1px; }
  .page-main-projects-box-date {
    display: block;
    font-weight: 400;
    color: #54BEBF;
    margin-bottom: 4px; }
  .page-main-projects-box-desc {
    color: #2A3546; }
  .page-main-projects-box-content {
    display: flex; }
  .page-main-projects-box-left {
    position: relative;
    max-width: calc(100% - 137px);
    flex-basis: calc(100% - 137px);
    padding-right: 30px; }
    .page-main-projects-box-left:after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      right: 0;
      width: 1px;
      height: 132px;
      background-color: #D3D3D3; }
  .page-main-projects-box-right {
    max-width: 137px;
    flex-basis: 137px;
    padding-left: 30px; }
    .page-main-projects-box-right p {
      color: #2A3546;
      margin-bottom: 9px; }
  .page-main-projects .btn-blue-icon {
    margin-top: 58px; }

.page-main-levelup {
  padding-top: 66px;
  padding-bottom: 140px; }
  .page-main-levelup .page-main-introduction-title:before, .page-main-levelup .page-main-introduction-title:after {
    display: none; }
  .page-main-levelup-content {
    max-width: 461px;
    margin: 0 auto; }
    .page-main-levelup-content .btn {
      margin-top: 32px; }

@media screen and (max-width: 1680px) {
  .page-header-main {
    padding-bottom: 10vw; }
    .page-header-main.page-header {
      padding-top: 10vw; }
    .page-header-main .page-header-img {
      width: 55vw;
      height: auto; }
  .page-main-projects-box-left {
    max-width: calc(100% - 106px);
    flex-basis: calc(100% - 106px);
    padding-right: 15px; }
  .page-main-projects-box-right {
    max-width: 106px;
    flex-basis: 106px;
    padding-left: 15px; }
  .page-main-projects-box-title.h4 {
    font-size: 24px; } }

@media screen and (max-width: 1199px) {
  .page-header-main.page-header {
    padding-top: 70px; }
  .page-header-main .page-header-img {
    right: 0; }
  .page-main-services-title, .page-main-services-subtitle {
    text-align: center; }
  .page-main-services-subtitle:after {
    margin-right: auto;
    margin-left: auto; }
  .page-main-services-left {
    max-width: 100%;
    flex-basis: 100%; }
    .page-main-services-left-desktop {
      width: 80%; }
  .page-main-services-right.col-6 {
    max-width: 100%;
    flex-basis: 100%; }
  .page-main-services .container-fluid {
    max-width: 960px;
    margin: 0 auto; }
  .page-main-projects {
    padding-top: 120px; }
    .page-main-projects-box-inner {
      padding: 18px;
      padding-bottom: 25px; }
    .page-main-projects-box-action-circle {
      min-width: 55px;
      width: 55px;
      height: 55px; }
    .page-main-projects-box-content {
      flex-wrap: wrap; }
    .page-main-projects-box-left, .page-main-projects-box-right {
      max-width: 100%;
      flex-basis: 100%; }
    .page-main-projects-box-left {
      padding-right: 0;
      border-bottom: 1px solid #D3D3D3;
      padding-bottom: 15px;
      margin-bottom: 15px; }
      .page-main-projects-box-left:after {
        display: none; }
    .page-main-projects-box-right {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0; }
      .page-main-projects-box-right p {
        max-width: 50%;
        flex-basis: 50%; } }

@media screen and (max-width: 991px) {
  .page-main-introduction-box {
    margin-bottom: 30px; }
  .page-main-services .container-fluid {
    max-width: 720px; }
  .page-main-projects {
    padding-top: 64px; }
    .page-main-projects .col-12.text-uppercase {
      display: block; }
      .page-main-projects .col-12.text-uppercase .h3 {
        margin-bottom: 55px; }
    .page-main-projects-box {
      margin-bottom: 30px; }
      .page-main-projects-box-inner:hover {
        -ms-transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1); }
      .page-main-projects-box-picture img {
        width: 100%; }
    .page-main-projects .btn-blue-icon {
      margin-top: 21px; }
  .page-main-levelup {
    padding-top: 39px;
    padding-bottom: 54px; } }

@media screen and (max-width: 768px) {
  .page-header-main {
    padding-bottom: 0; }
    .page-header-main.page-header {
      padding-top: 21px; }
      .page-header-main.page-header .page-header-img {
        position: static;
        width: 335px;
        margin-left: auto;
        margin-right: -15px; }
  .page-main-services .container-fluid {
    max-width: 540px; }
  .page-main-services .page-main-introduction-box {
    margin-bottom: 30px; }
    .page-main-services .page-main-introduction-box-1, .page-main-services .page-main-introduction-box-2, .page-main-services .page-main-introduction-box-3, .page-main-services .page-main-introduction-box-4 {
      min-height: auto;
      padding-bottom: 0; } }

@media screen and (max-width: 575px) {
  .page-main-introduction {
    padding-top: 61px; }
    .page-main-introduction-icon {
      display: none !important; }
    .page-main-introduction-title {
      position: relative;
      display: table;
      margin: 0 auto -2px; }
      .page-main-introduction-title:before, .page-main-introduction-title:after {
        content: '';
        position: absolute;
        display: inline-block;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto;
        z-index: -1; }
      .page-main-introduction-title:before {
        left: -110px;
        bottom: -42px;
        width: 87px;
        height: 65px;
        background-image: url("../img/icons/pattern-left.svg"); }
      .page-main-introduction-title:after {
        right: -67px;
        bottom: -42px;
        width: 55px;
        height: 27px;
        background-image: url("../img/icons/pattern-right.svg"); }
    .page-main-introduction-content .btn {
      margin-top: 48px; }
    .page-main-introduction-picture-desktop.img-center {
      display: none; }
    .page-main-introduction-picture-mobil {
      display: block;
      margin-top: 47px;
      margin-left: -15px; }
    .page-main-introduction-box {
      flex-wrap: wrap;
      margin-bottom: 42px; }
      .page-main-introduction-box-icon {
        margin-right: 0;
        margin-bottom: 8px; }
      .page-main-introduction-box h3 {
        margin-bottom: 13px; }
      .page-main-introduction-box-content {
        max-width: 100%;
        flex-basis: 100%; }
  .page-main-services {
    padding-top: 19px; }
    .page-main-services-title {
      position: relative;
      display: table;
      font-size: 33px;
      font-weight: 400;
      color: #FFF;
      background-color: #44C3B8;
      padding: 5px 10px; }
      .page-main-services-title:after {
        content: '';
        display: block;
        position: absolute;
        left: -15px;
        bottom: -63px;
        width: 298px;
        height: 50px;
        background-image: url("../img/page-title-after.svg");
        background-position: left top;
        background-repeat: no-repeat;
        background-size: contain; }
    .page-main-services-subtitle {
      position: relative;
      display: table;
      font-size: 27px;
      color: #FFF;
      background-color: #2A3546;
      padding: 9px 10px;
      margin-top: -3px;
      margin-bottom: 58px;
      margin-left: 34px; }
      .page-main-services-subtitle.line:after {
        display: none; }
    .page-main-services-left-desktop {
      display: none; }
    .page-main-services-left-mobil {
      display: block;
      width: 100%; }
    .page-main-services-right {
      padding-top: 0;
      margin-top: -78px; }
    .page-main-services .page-main-introduction-box {
      margin-bottom: 58px; } }
