/* Heading */
/* Lead */
/* FontFamily */
@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/MarkPro-Regular.eot?#prefix");
  src: url("fonts/MarkPro-Regular.eot?#iefix") format("eot"), url("fonts/MarkPro-Regular.otf") format("otf"), url("fonts/MarkPro-Regular.woff") format("woff"), url("fonts/MarkPro-Regular.woff2") format("woff2"), url("fonts/MarkPro-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("fonts/MarkPro-Bold.eot?#prefix");
  src: url("fonts/MarkPro-Bold.eot?#iefix") format("eot"), url("fonts/MarkPro-Bold.otf") format("otf"), url("fonts/MarkPro-Bold.woff") format("woff"), url("fonts/MarkPro-Bold.woff2") format("woff2"), url("fonts/MarkPro-Bold.ttf") format("truetype"); }

/* Text VAL */
body .hero {
  font-size: 61px;
  line-height: 1.2623; }

body h1, body .h1 {
  font-size: 59px;
  line-height: 1.18644; }

body h2, body .h2 {
  font-size: 45px;
  line-height: 1.26667; }

body h3, body .h3 {
  font-size: 40px;
  line-height: 1.225; }

body h4, body .h4 {
  font-size: 30px;
  line-height: 1.63333; }

body h5, body .h5 {
  font-size: 26px;
  line-height: 1.19231; }

.f-68 {
  font-size: 68px;
  line-height: 1.02941; }

.f-25 {
  font-size: 25px;
  line-height: 1.24; }

.f-20 {
  font-size: 20px;
  line-height: 1.25; }

.f-17 {
  font-size: 17px;
  line-height: 1.23529; }

.f-16 {
  font-size: 16px;
  line-height: 1.25; }

@media screen and (max-width: 1199px) {
  body .hero {
    font-size: 40px; }
  .f-20 {
    font-size: 17px; } }

@media screen and (max-width: 575px) {
  body .hero {
    font-size: 31px; }
  body h3, body .h3 {
    font-size: 27px;
    line-height: 1; }
  body h4, body .h4 {
    font-size: 20px;
    line-height: 1.35; }
  .f-20 {
    font-size: 15px;
    line-height: 1.2666666667; } }

/* Grid Modifiers */
/* Grid Responsive Prefix */
/* Container Responsive Width*/
/* Container Responsive Width */
.footer {
  background-color: #2A3546;
  padding-top: 86px; }
  .footer-logo {
    display: inline-block;
    margin-bottom: 38px; }
  .footer p, .footer a {
    color: #FFF; }
  .footer-col {
    padding-top: 9px;
    padding-bottom: 9px; }
    .footer-col-left {
      border-right: 1px solid #FFF; }
      .footer-col-left .f-20 {
        line-height: 43px; }
      .footer-col-left a:hover {
        text-decoration: underline; }
    .footer-col-center {
      display: flex;
      align-items: center;
      border-right: 1px solid #FFF; }
      .footer-col-center-social {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 159px;
        height: 100%;
        margin: 0 auto; }
        .footer-col-center-social a {
          max-width: 100%;
          flex-basis: 100%;
          display: flex;
          display: -webkit-flex;
          align-items: center;
          justify-content: space-between; }
    .footer-col-right {
      display: flex;
      align-items: center; }
      .footer-col-right-links {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 240px;
        height: 100%;
        margin: 0 auto; }
        .footer-col-right-links a {
          max-width: 100%;
          flex-basis: 100%;
          display: flex;
          display: -webkit-flex;
          align-items: center;
          justify-content: space-between; }
          .footer-col-right-links a:hover {
            text-decoration: underline; }
  .footer-copyright {
    padding-top: 64px;
    padding-bottom: 32px; }

@media screen and (max-width: 991px) {
  .footer-col-left, .footer-col-center {
    border: none; }
  .footer-col-left {
    margin-bottom: 20px; }
    .footer-col-left .f-20 {
      line-height: 1.7; }
  .footer-col-center {
    margin-bottom: 20px; }
    .footer-col-center-social {
      justify-content: flex-start;
      max-width: unset;
      width: 100%;
      margin-left: -15px; }
      .footer-col-center-social a {
        max-width: unset;
        flex-basis: unset;
        font-size: 0;
        text-indent: -9999px;
        margin: 0 15px; }
        .footer-col-center-social a img {
          width: auto;
          height: 28px; }
  .footer-col-right-links {
    max-width: unset;
    flex-basis: unset;
    width: 100%;
    flex-direction: column;
    align-items: flex-start; }
    .footer-col-right-links a {
      display: table;
      max-width: unset;
      flex-basis: unset;
      margin-bottom: 10px; } }

@media screen and (max-width: 575px) {
  .footer {
    padding-top: 45px; }
    .footer-logo {
      display: table;
      margin: 0 auto 30px; }
    .footer-col-left {
      text-align: center; }
    .footer-col-center {
      margin-bottom: 20px; }
      .footer-col-center-social {
        justify-content: center;
        margin-left: 0; }
    .footer-col-right-links {
      align-items: center;
      text-align: center; }
    .footer-copyright {
      padding-top: 30px; } }
