/* Heading */
/* Lead */
/* FontFamily */
@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/MarkPro-Regular.eot?#prefix");
  src: url("fonts/MarkPro-Regular.eot?#iefix") format("eot"), url("fonts/MarkPro-Regular.otf") format("otf"), url("fonts/MarkPro-Regular.woff") format("woff"), url("fonts/MarkPro-Regular.woff2") format("woff2"), url("fonts/MarkPro-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("fonts/MarkPro-Bold.eot?#prefix");
  src: url("fonts/MarkPro-Bold.eot?#iefix") format("eot"), url("fonts/MarkPro-Bold.otf") format("otf"), url("fonts/MarkPro-Bold.woff") format("woff"), url("fonts/MarkPro-Bold.woff2") format("woff2"), url("fonts/MarkPro-Bold.ttf") format("truetype"); }

/* Text VAL */
body .hero {
  font-size: 61px;
  line-height: 1.2623; }

body h1, body .h1 {
  font-size: 59px;
  line-height: 1.18644; }

body h2, body .h2 {
  font-size: 45px;
  line-height: 1.26667; }

body h3, body .h3 {
  font-size: 40px;
  line-height: 1.225; }

body h4, body .h4 {
  font-size: 30px;
  line-height: 1.63333; }

body h5, body .h5 {
  font-size: 26px;
  line-height: 1.19231; }

.f-68 {
  font-size: 68px;
  line-height: 1.02941; }

.f-25 {
  font-size: 25px;
  line-height: 1.24; }

.f-20 {
  font-size: 20px;
  line-height: 1.25; }

.f-17 {
  font-size: 17px;
  line-height: 1.23529; }

.f-16 {
  font-size: 16px;
  line-height: 1.25; }

@media screen and (max-width: 1199px) {
  body .hero {
    font-size: 40px; }
  .f-20 {
    font-size: 17px; } }

@media screen and (max-width: 575px) {
  body .hero {
    font-size: 31px; }
  body h3, body .h3 {
    font-size: 27px;
    line-height: 1; }
  body h4, body .h4 {
    font-size: 20px;
    line-height: 1.35; }
  .f-20 {
    font-size: 15px;
    line-height: 1.2666666667; } }

/* Grid Modifiers */
/* Grid Responsive Prefix */
/* Container Responsive Width*/
/* Container Responsive Width */
.navbar {
  margin-top: 53px; }
  .navbar .row {
    align-items: center;
    height: 73px; }
  .navbar-left li a {
    display: block; }
  .navbar-right {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: -26px; }
    .navbar-right li {
      margin: 0 26px; }
      .navbar-right li a {
        position: relative;
        font-size: 20px;
        color: #2A3546; }
        .navbar-right li a:before, .navbar-right li a:after {
          content: '';
          display: block;
          position: absolute;
          background-color: #2A3546;
          -moz-transition: all 400ms ease-in-out;
          -o-transition: all 400ms ease-in-out;
          -webkit-transition: all 400ms ease-in-out;
          transition: all 400ms ease-in-out; }
        .navbar-right li a:before {
          left: -9px;
          bottom: -7px;
          width: 0;
          height: 6px; }
        .navbar-right li a:after {
          left: -9px;
          bottom: -5px;
          width: 0;
          height: 2px; }
      .navbar-right li:hover a:before {
        width: 21px; }
      .navbar-right li.active a {
        font-weight: 600; }
        .navbar-right li.active a:before {
          width: 21px; }
        .navbar-right li.active a:after {
          width: calc(100% + 18px); }
  .navbar-menu {
    position: relative;
    cursor: pointer;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    display: none;
    width: 30px;
    height: 23px;
    margin-left: auto;
    margin-right: 15px;
    z-index: 1001; }
    .navbar-menu .bar {
      position: relative;
      display: block;
      width: 30px;
      height: 4px;
      background-color: #000;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      background-clip: border-box;
      -moz-transition: background 0.3s ease-in-out;
      -o-transition: background 0.3s ease-in-out;
      -webkit-transition: background 0.3s ease-in-out;
      transition: background 0.3s ease-in-out;
      transition-delay: 0.3s; }
      .navbar-menu .bar:before, .navbar-menu .bar:after {
        content: '';
        position: absolute;
        right: 0;
        display: block;
        width: 15px;
        height: 4px;
        background-color: #000;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        background-clip: border-box;
        -ms-transform-origin: 50% 50%;
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        transition: top .3s ease-in-out, transform .3s ease-in-out, width .3s ease;
        transition-delay: 0.3s, 0s, 0.3s; }
      .navbar-menu .bar:before {
        top: -10px;
        left: 0; }
      .navbar-menu .bar:after {
        top: 10px; }

@media screen and (max-width: 1280px) {
  .navbar {
    margin-top: 20px; } }

@media screen and (max-width: 1199px) {
  .navbar {
    margin-top: 0; }
    .navbar-right li {
      margin: 0 10px; } }

@media screen and (max-width: 991px) {
  .navbar-menu {
    display: flex; }
  .navbar-right {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: #FFF;
    -ms-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
    transition-delay: 400ms;
    -webkit-transition-delay: 400ms;
    margin-right: 0;
    z-index: 1000; }
    .navbar-right a {
      display: table;
      font-size: 30px;
      font-weight: 600;
      line-height: 1.2;
      color: #000;
      text-align: center;
      opacity: 0;
      visibility: hidden;
      -ms-transform: translateX(-40px);
      -webkit-transform: translateX(-40px);
      -moz-transform: translateX(-40px);
      -o-transform: translateX(-40px);
      transform: translateX(-40px);
      -moz-transition: all 600ms ease-in-out;
      -o-transition: all 600ms ease-in-out;
      -webkit-transition: all 600ms ease-in-out;
      transition: all 600ms ease-in-out;
      padding: 10px;
      margin: 0 auto; }
      .navbar-right a.sys {
        opacity: 1;
        visibility: visible;
        -ms-transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0); }
      .navbar-right a.hys {
        opacity: 0;
        visibility: hidden;
        -ms-transform: translateX(40px);
        -webkit-transform: translateX(40px);
        -moz-transform: translateX(40px);
        -o-transform: translateX(40px);
        transform: translateX(40px); } }

@media screen and (max-width: 768px) {
  .navbar {
    margin-top: 0; } }

@media screen and (max-width: 575px) {
  .navbar .row {
    height: 45px; }
  .navbar-left {
    padding-left: 15px; }
  .navbar-brand {
    width: 96px;
    height: auto; } }
