/* Heading */
/* Lead */
/* FontFamily */
@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/MarkPro-Regular.eot?#prefix");
  src: url("fonts/MarkPro-Regular.eot?#iefix") format("eot"), url("fonts/MarkPro-Regular.otf") format("otf"), url("fonts/MarkPro-Regular.woff") format("woff"), url("fonts/MarkPro-Regular.woff2") format("woff2"), url("fonts/MarkPro-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("fonts/MarkPro-Bold.eot?#prefix");
  src: url("fonts/MarkPro-Bold.eot?#iefix") format("eot"), url("fonts/MarkPro-Bold.otf") format("otf"), url("fonts/MarkPro-Bold.woff") format("woff"), url("fonts/MarkPro-Bold.woff2") format("woff2"), url("fonts/MarkPro-Bold.ttf") format("truetype"); }

/* Text VAL */
body .hero {
  font-size: 61px;
  line-height: 1.2623; }

body h1, body .h1 {
  font-size: 59px;
  line-height: 1.18644; }

body h2, body .h2 {
  font-size: 45px;
  line-height: 1.26667; }

body h3, body .h3 {
  font-size: 40px;
  line-height: 1.225; }

body h4, body .h4 {
  font-size: 30px;
  line-height: 1.63333; }

body h5, body .h5 {
  font-size: 26px;
  line-height: 1.19231; }

.f-68 {
  font-size: 68px;
  line-height: 1.02941; }

.f-25 {
  font-size: 25px;
  line-height: 1.24; }

.f-20 {
  font-size: 20px;
  line-height: 1.25; }

.f-17 {
  font-size: 17px;
  line-height: 1.23529; }

.f-16 {
  font-size: 16px;
  line-height: 1.25; }

@media screen and (max-width: 1199px) {
  body .hero {
    font-size: 40px; }
  .f-20 {
    font-size: 17px; } }

@media screen and (max-width: 575px) {
  body .hero {
    font-size: 31px; }
  body h3, body .h3 {
    font-size: 27px;
    line-height: 1; }
  body h4, body .h4 {
    font-size: 20px;
    line-height: 1.35; }
  .f-20 {
    font-size: 15px;
    line-height: 1.2666666667; } }

/* Grid Modifiers */
/* Grid Responsive Prefix */
/* Container Responsive Width*/
/* Container Responsive Width */
.page-header-contact {
  padding-bottom: 14px; }
  .page-header-contact.page-header {
    padding-top: 10vw; }
  .page-header-contact .page-header-content {
    padding-left: 35px;
    margin-left: 0; }
  .page-header-contact .page-header-img {
    margin-top: -167px; }

.page-contact {
  padding-bottom: 122px; }
  .page-contact-left-box:not(.page-contact-left-box-last) {
    margin-bottom: 67px; }
  .page-contact-left .text-uppercase {
    margin-bottom: 69px; }
  .page-contact-right .text-uppercase {
    margin-bottom: 47px; }
  .page-contact .contact-item {
    display: flex;
    align-items: center; }
    .page-contact .contact-item:not(:last-child) {
      margin-bottom: 47px; }
    .page-contact .contact-item-icon {
      max-width: 30px;
      flex-basis: 30px;
      margin-right: 34px; }
      .page-contact .contact-item-icon img {
        display: table;
        margin: 0 auto; }
    .page-contact .contact-item a, .page-contact .contact-item p {
      font-weight: 600;
      font-size: 20px;
      color: #2A3546;
      line-height: 1.25; }

@media screen and (max-width: 991px) {
  .page-header-contact {
    margin-bottom: 51px; }
    .page-header-contact.page-header {
      padding-top: 60px; }
    .page-header-contact .page-header-img {
      margin-top: -60px; }
  .page-contact-left {
    order: 2; }
  .page-contact-right {
    order: 1;
    margin-bottom: 69px; } }

@media screen and (max-width: 768px) {
  .page-header-contact.page-header {
    padding-top: 17px; }
  .page-header-contact .page-header-content {
    order: 2;
    margin-top: -144px;
    padding-left: 15px; }
  .page-header-contact .page-header-picture {
    order: 1; }
  .page-header-contact .page-header-img {
    margin-top: 0;
    margin-left: auto;
    margin-right: -15px; } }

@media screen and (max-width: 575px) {
  .page-contact {
    padding-bottom: 60px; }
    .page-contact-left .text-uppercase {
      margin-bottom: 42px; }
    .page-contact-right .text-uppercase {
      margin-bottom: 0; }
    .page-contact .contact-item-icon {
      margin-right: 24px; }
    .page-contact .contact-item a, .page-contact .contact-item p {
      font-size: 17px; }
    .page-contact .page-main-introduction-title {
      display: block;
      margin-left: 0; }
      .page-contact .page-main-introduction-title:before, .page-contact .page-main-introduction-title:after {
        display: none; }
    .page-contact .page-main-introduction-subtitle {
      margin-bottom: 26px; } }
